<template>
  <div class="HotelImage-container">
    <div
      class="angry-grid"
      v-if="!isMobile && hotelsInfo && hotelsInfo.images && Array.isArray(hotelsInfo.images) && hotelsInfo.images.length"
    >
      <div
        v-for="(item, index) in hotelsInfo.images"
        :key="index"
        :id="'item-' + index"
        @click="openDialog(index)"
        :class="{ 'more-images': index === 6 }"
      >
        <img v-if="!item.image_path && index <= 7" src="@/assets/imgs/img.jpg" alt="" />
        <img v-if="item.image_path && index <= 7" :src="item.image_path" alt="" />
        <div v-if="index === 6" class="more-images-overlay">
          +{{ hotelsInfo.images.length - 6 }} Photos
        </div>
      </div>
      <div id="item-7" 
      @click="openDialog(0)"
    ><img :src="hotelsInfo.image" alt="" /></div>
    </div>

    <div class="mobile-slider" v-if="isMobile && hotelsInfo && hotelsInfo.images && Array.isArray(hotelsInfo.images) && hotelsInfo.images.length">
      <v-carousel v-model="currentIndex" hide-delimiters height="400px">
        <template v-slot:prev>
          <v-btn icon @click="prev" class="carousel-button">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
        </template>
        <template v-slot:next>
          <v-btn icon @click="next" class="carousel-button">
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </template>
        <v-carousel-item
          v-for="(item, index) in hotelsInfo.images"
          :key="index"
          :src="item.image_path || '@/assets/imgs/img.jpg'"
        ></v-carousel-item>
      </v-carousel>
      <div class="image-count">
        {{ currentIndex + 1 }} / {{ hotelsInfo.images.length }}
      </div>
      <div class="thumbnails">
        <div
          v-for="(item, index) in hotelsInfo.images"
          :key="index"
          class="thumbnail-wrapper"
          :class="{ active: currentIndex === index }"
          @click="currentIndex = index"
        >
          <img
            :src="item.image_path || '@/assets/imgs/img.jpg'"
            class="thumbnail"
            :class="{ selected: currentIndex === index }"
          />
        </div>
      </div>
    </div>

    <v-dialog v-if="!isMobile && hotelsInfo && hotelsInfo.images && Array.isArray(hotelsInfo.images) && hotelsInfo.images.length" v-model="dialog" max-width="800px">
      <v-card>
        <v-card-title class="dialog-header">
          <span>{{ hotelsInfo.title }}</span>
          <v-spacer></v-spacer>
          <v-btn icon @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="dialog-content">
          <v-carousel v-model="currentIndex" hide-delimiters height="400px">
            <template v-slot:prev>
              <v-btn icon @click="prev" class="carousel-button">
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
            </template>
            <template v-slot:next>
              <v-btn icon @click="next" class="carousel-button">
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </template>
            <v-carousel-item
              v-for="(item, index) in hotelsInfo.images"
              :key="index"
              :src="item.image_path || '@/assets/imgs/img.jpg'"
            ></v-carousel-item>
          </v-carousel>
          <div class="image-count">
            {{ currentIndex + 1 }} / {{ hotelsInfo.images.length }}
          </div>
          <div class="thumbnails">
            <div
              v-for="(item, index) in hotelsInfo.images"
              :key="index"
              class="thumbnail-wrapper"
              :class="{ active: currentIndex === index }"
              @click="currentIndex = index"
            >
              <img
                :src="item.image_path || '@/assets/imgs/img.jpg'"
                class="thumbnail"
                :class="{ selected: currentIndex === index }"
              />
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script setup>
/* eslint-disable */
import { onMounted, ref, defineProps } from "vue";
import { useMediaQuery } from '@vueuse/core';

const props = defineProps(["hotelsData"]);
const hotelsInfo = ref({});
const dialog = ref(false);
const currentIndex = ref(0);
const isMobile = useMediaQuery('(max-width: 1150px)');

const showHotel = async () => {
  try {
    hotelsInfo.value = props.hotelsData;
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

const openDialog = (index) => {
  currentIndex.value = index;
  dialog.value = true;
};

const prev = () => {
  currentIndex.value =
    (currentIndex.value - 1 + hotelsInfo.value.images.length) %
    hotelsInfo.value.images.length;
};

const next = () => {
  currentIndex.value = (currentIndex.value + 1) % hotelsInfo.value.images.length;
};

onMounted(showHotel);
</script>

<style scoped>
.HotelImage-container {
  font-family: "Cairo", sans-serif;
  width: 100%;
  margin: auto;
}

.angry-grid img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.angry-grid div img {
  border-radius: 10px;
}

.angry-grid {
  display: grid;
  gap: 3px;
  height: 550px;
  width: 100%;
}

.angry-grid > div {
  cursor: pointer;
  position: relative;
}

.more-images img {
  filter: blur(5px);
}

.more-images-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  color: white;
  background: rgba(0, 0, 0, 0.6);
  border-radius: 10px;
}

.v-carousel-item {
  height: 400px;
}

.carousel-button {
  background: none;
  box-shadow: none;
}

.dialog-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  font-weight: bold;
}

.dialog-content {
  padding: 16px;
  text-align: center;
}

.image-count {
  margin-top: 10px;
  text-align: center;
  font-size: 16px;
  color: #777;
}

.thumbnails {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  overflow-x: auto;
}

.thumbnail-wrapper {
  padding: 5px;
  opacity: 0.5;
}

.thumbnail-wrapper.active {
  opacity: 1;
}

.thumbnail-wrapper.active .thumbnail {
  border: 2px solid #ff9800;
}

.thumbnail {
  width: 60px;
  height: 60px;
  margin: 0 5px;
  cursor: pointer;
  border: 2px solid transparent;
  transition: border 0.3s;
  object-fit: cover;
}

.thumbnail.selected {
  border: 2px solid #ff9800;
}

.thumbnail:hover {
  border: 2px solid #ff9800;
}

@media (min-width: 1024px) {
  .more-images-overlay {

  font-size: 16px;

}
  .angry-grid {
    grid-template-rows: repeat(8, 1fr);
    grid-template-columns: repeat(10, 1fr);
  }
  #item-0 {
    grid-row: 1 / 4;
    grid-column: 1 / 4;
  }
  #item-1 {
    grid-row: 4 / 7;
    grid-column: 1 / 4;
  }
  #item-2 {
    grid-row: 7 / 9;
    grid-column: 1 / 3;
  }
  #item-3 {
    grid-row: 7 / 9;
    grid-column: 3 / 5;
  }
  #item-4 {
    grid-row: 7 / 9;
    grid-column: 5 / 7;
  }
  #item-5 {
    grid-row: 7 / 9;
    grid-column: 7 / 9;
  }
  #item-6 {
    grid-row: 7 / 9;
    grid-column: 9 / 11;
  }
  #item-7 {
    grid-row: 1 / 7;
    grid-column: 4 / 11;
    position: relative;
  }
}

@media (max-width: 1023px) {
  .angry-grid {
    grid-template-rows: repeat(8, 1fr);
    grid-template-columns: repeat(8, 1fr);
  }
  #item-0 {
    grid-row: 1 / 4;
    grid-column: 1 / 5;
  }
  #item-1 {
    grid-row: 4 / 7;
    grid-column: 1 / 5;
  }
  #item-2 {
    grid-row: 7 / 9;
    grid-column: 1 / 3;
  }
  #item-3 {
    grid-row: 7 / 9;
    grid-column: 3 / 5;
  }
  #item-4 {
    grid-row: 1 / 4;
    grid-column: 5 / 9;
  }
  #item-5 {
    grid-row: 4 / 7;
    grid-column: 5 / 9;
  }
  #item-6 {
    grid-row: 7 / 9;
    grid-column: 5 / 9;
  }
  #item-7 {
    grid-row: 1 / 9;
    grid-column: 9 / 13;
    position: relative;
  }
}

@media (max-width: 767px) {
  .angry-grid {
    grid-template-rows: repeat(8, 1fr);
    grid-template-columns: repeat(4, 1fr);
  }
  #item-0,
  #item-1,
  #item-2,
  #item-3,
  #item-4,
  #item-5,
  #item-6,
  #item-7 {
    grid-row: span 2;
    grid-column: span 4;
  }
}

.mobile-slider {
  width: 100%;
  overflow: hidden;
}
</style>
