<template>
  <div class="slider">
    <div class="fade-container">
      <div :class="[{ rtl: locale === 'ar' || locale === 'kr' }, 'slider-text-bottom']">
        {{ $t("message.sliderTextBottomHotel") }}
      </div>
      <div class="overlay"></div>
    </div>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";

export default {
  name: "SliderComponent",
  setup() {
    const { locale, t } = useI18n();
    return { locale, t };
  },
};
</script>

<style scoped>
.fade-container {
  font-family: "Cairo", sans-serif;
  position: relative;
  height: 255px; /* the fixed height from the UI design */
}

.slider {
  width: 100%;
  overflow: hidden;
  position: relative;
}

.slider-text-bottom {
  width: 50%;
  position: absolute;
  top: 38%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  font-size: 2em;
  text-align: center;
  z-index: 10;
}

.rtl.slider-text-bottom {
  direction: rtl;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #003b7d; /* Background color */
  z-index: 5; /* Putting it above the images, below the text */
}

/* Responsive Design */
@media (min-width: 768px) {
  .fade-container {
    height: 300px; /* Increase height for tablets */
  }

  .slider-text-bottom {
    font-size: 2em;
  }
}
@media (max-width: 768px) {
  .fade-container {
    height: 300px; /* Increase height for tablets */
  }

  .slider-text-bottom {
    font-size: 1.5em;
  }
}

@media (min-width: 1024px) {
  .fade-container {
    height: 350px; /* Increase height for desktops */
  }

  .slider-text-bottom {
    font-size: 2.5em;
  }
}
</style>
