import { createRouter, createWebHistory } from 'vue-router';
import MainPage from '../views/MainPage.vue';
import HotelList from '../views/HotelList.vue';
import Faq from '../views/Faq.vue';
import OurOffers from '../views/OurOffers.vue';
import ContactUs from '../views/ContactUs.vue';
import ShowHotel from '../views/ShowHotel.vue';
import RateMyReservations from "../views/RateReservations.vue"
import AccountSetting from '../views/AccountSetting.vue';
import Favorites from '../views/Favorites.vue';
import MyBookings from '../views/MyBookings.vue';
import BookHotelRoom from '../views/BookHotelRoom.vue';
import RoomConfirmation from '../views/RoomConfirmationView.vue';
import Login from '../views/Registration/Login.vue';
import SignUp from '../views/Registration/SignUp.vue';
import PasswordRecovery from '../views/Registration/PasswordRecovery.vue';
import VerifyOtp from '../views/Registration/VerifyOtp.vue';
import PasswordRecovery2 from '../views/Registration/PasswordRecovery2.vue';
import PasswordRecovery3 from '../views/Registration/PasswordRecovery3.vue';
import TermsAndConditions from '../views/TermsAndConditions.vue';
import PrivacyPolicy from '../views/PrivacyPolicy.vue';


const routes = [
  {
    path: '/',
    component: MainPage,
  },
  {
    path: '/hotels',
    name: 'Hotels',
    component: HotelList,
  },
  {
    path: '/faq',
    component: Faq,
  },
  {
    path: '/offers',
    component: OurOffers,
  },
  {
    path: '/contact',
    component: ContactUs,
  },
  {
    path: '/hotel',
    name: 'Hotel',
    component: ShowHotel,
  },
  {
    path: '/hotel/:id/reserve/:token',
    name: 'HotelReservationRate',
    component: RateMyReservations,
  },
  {
    path: '/hotel/book',
    name: 'HotelBooking',
    component: BookHotelRoom,
  },
  {
    path: '/hotel/booking-confirmation/:id',
    name: 'makeReservation',
    component: RoomConfirmation,
  },
  {
    path: '/login',
    component: Login,
  },
  {
    path: '/signup',
    component: SignUp,
  },
  {
    path: '/verify-otp/:phone',
    component: VerifyOtp,
  },
  {
    path: '/password-recovery',
    component: PasswordRecovery,
  },
  {
    path: '/password-recovery2',
    component: PasswordRecovery2,
  },
  {
    path: '/password-recovery3',
    component: PasswordRecovery3,
  },
  {
    path: '/account-setting',
    component: AccountSetting,
  },
  {
    path: '/favorites',
    component: Favorites,
  },
  {
    path: '/my-bookings',
    component: MyBookings,
  },
  {
    path: '/terms-and-conditions',
    component: TermsAndConditions,
  },
  {
    path: '/privacy-policy',
    component: PrivacyPolicy,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
