<template>
  <div class="container">
    <div class="container-header">
      <div class="header-text-bottom">
        <button
          type="button"
          :class="{
            'text-white': activeButton === 'current',
            'text-blue go-to-contact-us': activeButton !== 'current',
          }"
          @click="activeButton = 'current'"
          class="focus:outline-none focus:ring-4 focus:ring-blue-300 font-medium rounded-full text-sm px-10 py-2.5 text-center"
        >
          الحجوزات السارية
        </button>
        <button
          type="button"
          :class="{
            'text-white ': activeButton === 'previous',
            'text-blue go-to-contact-us': activeButton !== 'previous',
          }"
          @click="activeButton = 'previous'"
          class="rounded-full text-sm px-10 py-2.5 text-center"
        >
          الحجوزات السابقة
        </button>
        <button
          type="button"
          :class="{
            'text-white': activeButton === 'uncompleted',
            'text-blue go-to-contact-us': activeButton !== 'uncompleted',
          }"
          @click="activeButton = 'uncompleted'"
          class="rounded-full text-sm px-10 py-2.5 text-center"
        >
          الحجوزات الغير مكتملة
        </button>
      </div>
    </div>

    <div v-if="activeButton === 'current'" class="content">
      <div
        class="card-container shadow hover:bg-gray-100"
        v-for="(item, index) in currentReservations"
        :key="index"
      >
        <div class="image-container">
          <img src="@/assets/imgs/bookings1.jpg" alt="" />
        </div>
        <div class="card-text">
          <div style="display: flex; justify-content: space-between">
            <h1
              style="font-weight: 800; font-size: 14px"
              dir="rtl"
              v-if="item && item.hotel"
            >
              {{ item.hotel.title }}
            </h1>
            <span class="font-medium rounded-full text-center start-count">
              <v-icon class="text-orange" size="18" icon="mdi-star"></v-icon
              ><span>4</span>
            </span>
          </div>
          <h1 dir="rtl" v-if="item && item.hotel.governorate">
            {{ item.hotel.governorate.ar_name }} \ {{ item.hotel.city.ar_name }}
            <!-- <span style="text-decoration: underline; color: orange; font-size: 12px"
              >أضهار على الخارطة</span
            > -->
          </h1>
          <span style="color: #888"
            >{{ item.checkout }} - {{ item.checkin }}
          </span>
          <span style="color: #003b7d; font-weight: 700" dir="ltr">
            {{ item.total_price_with_currency }} {{ $store.state.users.currency_selected }}
          </span>
          <div class="flex flex-col gap-2">
            <button
              type="button"
              class="text-black rounded-full text-sm py-1.5 text-center w-full bg-white border"
            >
              <!-- <i class="fa-solid fa-check ml-1"></i> -->
              <span style="color: #000; font-size: 12px"> رقم الحجز :</span>
              {{ item.reservation_no }}
            </button>
            <button
              class="text-white rounded-full text-sm py-1.5 text-center"
              @click="$store.dispatch('downloadReservation', {id: item.id, reservation_no: item.reservation_no })"
            >
            تحميل وصل الحجز
          </button>
          </div>
        </div>
      </div>
    </div>
    <div v-if="activeButton === 'uncompleted'" class="content">
      <div
        class="card-container shadow hover:bg-gray-100"
        v-for="(item, index) in unPaidReservations"
        :key="index"
      >
        <div class="image-container">
          <img src="@/assets/imgs/bookings1.jpg" alt="" />
        </div>
        <div class="card-text">
          <div style="display: flex; justify-content: space-between">
            <h1
              style="font-weight: 800; font-size: 14px"
              dir="rtl"
              v-if="item && item.hotel"
            >
              {{ item.hotel.title }}
            </h1>
            <span class="font-medium rounded-full text-center start-count">
              <v-icon class="text-orange" size="18" icon="mdi-star"></v-icon
              ><span>4</span>
            </span>
          </div>
          <h1 dir="rtl" v-if="item && item.hotel.governorate">
            {{ item.hotel.governorate.ar_name }} \ {{ item.hotel.city.ar_name }}
            <!-- <span style="text-decoration: underline; color: orange; font-size: 12px"
              >أضهار على الخارطة</span
            > -->
          </h1>
          <span style="color: #888"
            >{{ item.checkout }} - {{ item.checkin }}
          </span>
          <span style="color: #003b7d; font-weight: 700" dir="ltr">
            {{ item.total_price_with_currency }} {{ $store.state.users.currency_selected }}
          </span>
          <div class="flex flex-col gap-2">
            <button
              type="button"
              class="text-black rounded-full text-sm py-1.5 text-center w-full bg-white border"
            >
              <!-- <i class="fa-solid fa-check ml-1"></i> -->
              <span style="color: #000; font-size: 12px"> رقم الحجز :</span>
              {{ item.reservation_no }}
            </button>
            <button
              class="text-white rounded-full text-sm py-1.5 text-center"
              @click="$store.dispatch('downloadReservation', {id: item.id, reservation_no: item.reservation_no })"
            >
            تحميل وصل الحجز
          </button>
          </div>
        </div>
      </div>
    </div>
    <div v-if="activeButton === 'previous'" class="content">
      <div
        class="card-container shadow hover:bg-gray-100"
        v-for="(item, index) in previousReservations"
        :key="index"
      >
        <div class="image-container">
          <img src="@/assets/imgs/bookings1.jpg" alt="" />
        </div>
        <div class="card-text">
          <div style="display: flex; justify-content: space-between">
            <h1
              style="font-weight: 800; font-size: 14px"
              dir="rtl"
              v-if="item && item.hotel"
            >
              {{ item.hotel.title }}
            </h1>
            <span class="font-medium rounded-full text-center start-count">
              <v-icon class="text-orange" size="18" icon="mdi-star"></v-icon
              ><span>4</span>
            </span>
          </div>
          <h1 dir="rtl" v-if="item && item.hotel.governorate">
            {{ item.hotel.governorate.ar_name }} \ {{ item.hotel.city.ar_name }}
            <!-- <span style="text-decoration: underline; color: orange; font-size: 12px"
              >أضهار على الخارطة</span
            > -->
          </h1>
          <span style="color: #888"
            >{{ item.checkout }} - {{ item.checkin }}
          </span>
          <span style="color: #003b7d; font-weight: 700" dir="ltr">
            {{ item.total_price_with_currency }} {{ $store.state.users.currency_selected }}
          </span>
          <div class="flex flex-col gap-2">
            <button
              type="button"
              class="text-black rounded-full text-sm py-1.5 text-center w-full bg-white border"
            >
              <!-- <i class="fa-solid fa-check ml-1"></i> -->
              <span style="color: #000; font-size: 12px"> رقم الحجز :</span>
              {{ item.reservation_no }}
            </button>
            <button
              class="text-white rounded-full text-sm py-1.5 text-center"
              @click="$store.dispatch('downloadReservation', {id: item.id, reservation_no: item.reservation_no })"
            >
            تحميل وصل الحجز
          </button>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { ref, onMounted, computed } from "vue";
import { useStore } from "vuex";

export default {
  /* eslint-disable */
  name: "offersMainList",
  data() {
    return {
      activeButton: "current", // Set the default active button
    };
  },
  // components: {
  //   VSelect,
  // },
  setup() {
    const store = useStore(); // Vuex store instance
    const offers = ref([]);
    const currentReservations = ref([]);
    const unPaidReservations = ref([]);

    const previousReservations = computed(() => currentReservations.value.filter(r => new Date(r.checkout) < new Date()))

    const getCurrentReservations = async () => {
      try {
        await store.dispatch("getMyReservations").then((res) => {
          currentReservations.value = res.data;
        });
        // offers.value = store.getters.offers.data;
      } catch (error) {
        console.error("Error fetching Offers:", error);
      }
    };
    const getUnPaidReservationsReservations = async () => {
      try {
        await store.dispatch("getMyReservations", {take: 30, is_paid: 1}).then((res) => {
          unPaidReservations.value = res.data;
        });
        // offers.value = store.getters.offers.data;
      } catch (error) {
        console.error("Error fetching Offers:", error);
      }
    };

    // Fetch offers data when the component is mounted
    onMounted(() => {
      getCurrentReservations()
      getUnPaidReservationsReservations()
    });

    return { offers, currentReservations, unPaidReservations, previousReservations }; // Expose faq and toggleInfo to the template
  },
};
</script>

<style scoped>
.container {
  margin: auto;
  font-family: "Cairo", sans-serif;
  width: 70%;
  direction: rtl;
}
.content {
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
  gap: 25px;
}
.card-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: 175;
  width: 500px;
  border-radius: 10px;
}
.card-container:hover {
  cursor: pointer;
  transform: scale(1.05);
  transition: all 0.4s ease-in-out;
}
.card-text {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;
  font-size: 12px;
  margin: 10px;
  width: 325px;
  height: 100%;
}
.image-container {
  height: 160px;
  width: 160px;
}
.image-container img {
  width: 100%;
  border-radius: 10px;
  height: 100%;
  object-fit: cover;
  display: block;
}
.typing {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
}
.typing-header {
  font-size: 26px;
  color: #ffb325;
  font-weight: 600;
}
.typing-pargraph {
  font-size: 14px;
  color: #000;
}
.time-text {
  color: #ffb325;
}
.show-more-button {
  margin-top: 30px;
}
.show-more-button button {
  background-color: #003b7d;
}
.show-more-button button:hover {
  background-color: #043961;
  transition: all 0.5s ease-in-out;
}
.container-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
  margin-top: 25px;
}
.slider-buttons {
  margin-top: 2rem;
  display: flex;
  align-items: center;
  gap: 10px;
}
button:hover {
  opacity: 0.8;
  transition: all 0.3s ease-in-out;
}
.header-text-bottom {
  display: flex;
  /* justify-content: center; */
  align-items: center;
  gap: 10px;
}
button {
  background-color: #003b7d;
  font-weight: 600;
}
.go-to-contact-us {
  border: 2px solid #003b7d;
  color: #003b7d;
  background-color: #fff;
}
.start-count {
  font-size: 16px;
  background-color: #fafafa;
  padding: 2px 8px;
  display: flex;
  align-items: center;
  gap: 1px;
}
</style>
