<template>
  <div
    :class="[{ rtl: locale === 'ar' || locale === 'kr' }, 'containerHeader']"
  >
    <div class="content">
      <div class="whatsapp">
        <span>{{ $t("message.Whatsapp") }}</span>
        <i class="fab fa-whatsapp"></i>
      </div>
      <div class="left-header">
        <div v-if="userName.slice(0, 4) === 'user' || userName === 'Guest'">
          <router-link
            active-class="active"
            class="hover:text-red duration-500"
            to="/login"
          >
            <span class="signIn">{{ $t("message.SignIn") }}</span>
          </router-link>
          <router-link
            active-class="active"
            class="hover:text-red duration-500 mx-3"
            to="/signup"
          >
            <span class="account">{{ $t("message.SignUp") }}</span>
          </router-link>
        </div>
        <span
          @click="language = true"
          class="language-containerHeader"
          style="cursor: pointer"
        >
          <img class="language" :src="languageSelected.flag" alt="" />
        </span>
        <i
          @click="dialog = true"
          class="fas fa-dollar-sign"
          style="cursor: pointer"
        ></i>
        <router-link
          active-class="active"
          class="hover:text-red duration-500"
          to="/contact"
        >
          <i class="fas fa-headset"></i>
        </router-link>

        <div class="text-center pa-4">
          <v-dialog v-model="dialog" width="auto">
            <v-card width="1050">
              <v-card-title
                class="d-flex justify-space-between align-center mb-3"
                :style="{
                  direction: locale === 'ar' || locale === 'kr' ? 'rtl' : 'ltr',
                }"
              >
                <div style="color: orange; font-weight: 700">
                  {{ $t("message.SelectCurrency") }}
                </div>
                <v-btn
                  icon="mdi-close"
                  variant="text"
                  @click="dialog = false"
                ></v-btn>
              </v-card-title>
              <v-divider></v-divider>

              <div
                :style="{
                  direction: locale === 'ar' || locale === 'kr' ? 'rtl' : 'ltr',
                  color: 'orange',
                  fontWeight: 700,
                  fontSize: '16px',
                  margin: '0rem 2rem',
                }"
              >
                {{ $t("message.SelectedCurrency") }}
              </div>
              <div class="modal-containerHeader">
                <div class="currency-card-selected shadow">
                  {{ currency_selected }}
                </div>
              </div>
              <div
                :style="{
                  direction: locale === 'ar' || locale === 'kr' ? 'rtl' : 'ltr',
                  color: 'orange',
                  fontWeight: 700,
                  fontSize: '16px',
                  margin: '1rem 2rem',
                }"
              >
                {{ $t("message.AllCurrencies") }}
              </div>
              <div class="modal-containerHeader p-3">
                <div
                  class="currency-card shadow"
                  v-for="(item, index) in currencies"
                  :key="index"
                  @click="selectCurrency(item)"
                >
                  <img v-if="item.image" width="30" class="rounded-full" :src="item.image" alt="">
                  <div class="flex flex-col">
                    <template v-if="item.real_name">
                      <span class="font-bold">{{ item.real_name }}</span>
                      <span class="text-sm">{{ item.currency_name }}</span>
                    </template>
                    <template v-else>
                      {{ item.currency_name }}
                    </template>
                  </div>
                </div>
              </div>
            </v-card>
          </v-dialog>
          <v-dialog v-model="language" width="auto">
            <v-card width="1050">
              <v-card-title
                class="d-flex justify-space-between align-center mb-3"
                :style="{
                  direction: locale === 'ar' || locale === 'kr' ? 'rtl' : 'ltr',
                }"
              >
                <div style="color: orange; font-weight: 700">
                  {{ $t("message.SelectLanguage") }}
                </div>
                <v-btn
                  icon="mdi-close"
                  variant="text"
                  @click="language = false"
                ></v-btn>
              </v-card-title>
              <v-divider></v-divider>

              <div
                :style="{
                  direction: locale === 'ar' || locale === 'kr' ? 'rtl' : 'ltr',
                  color: 'orange',
                  fontWeight: 700,
                  fontSize: '16px',
                  margin: '0rem 2rem',
                }"
              >
                {{ $t("message.SelectedLanguage") }}
              </div>
              <div class="modal-containerHeader">
                <div class="language-card-selected shadow">
                  <span>{{ languageSelected.text }}</span>
                  <img class="language" :src="languageSelected.flag" alt="" />
                </div>
              </div>
              <div
                :style="{
                  direction: locale === 'ar' || locale === 'kr' ? 'rtl' : 'ltr',
                  color: 'orange',
                  fontWeight: 700,
                  fontSize: '16px',
                  margin: '1rem 2rem',
                }"
              >
                {{ $t("message.AllLanguages") }}
              </div>
              <div class="modal-containerHeader p-3">
                <div
                  class="language-card shadow"
                  v-for="(item, index) in languages"
                  :key="index"
                  @click="selectLanguage(item)"
                >
                  <span>{{ item.text }}</span>
                  <img class="language" :src="item.flag" alt="" />
                </div>
              </div>
            </v-card>
          </v-dialog>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onBeforeMount, ref } from "vue";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";

export default {
  name: "HeaderComp",
  setup() {
    const dialog = ref(false);
    const language = ref(false);
    const currencies = ref([]);
    const languages = ref([
      {
        text: "Arabic",
        ar_text: "العربية",
        flag: require("@/assets/country_flags/Flag_of_Iraq.png"),
        value: "ar",
      },
      {
        text: "English",
        ar_text: "الانكليزية",
        flag: require("@/assets/country_flags/Flag_of_united_kingdom.png"),
        value: "en",
      },
      {
        text: "Kurdish",
        ar_text: "الكردية",
        flag: require("@/assets/country_flags/Flag_of_kurdistan.png"),
        value: "kr",
      },
    ]);
    const currency_selected = ref("");

    const languageSelected = ref({
      text: "Arabic",
      ar_text: "العربية",
      flag: require("@/assets/country_flags/Flag_of_Iraq.png"),
      value: "ar",
    });
    const savedLanguage = localStorage.getItem("selectedLanguage");
    if (savedLanguage) {
      const item = JSON.parse(savedLanguage);
      languageSelected.value = item;
    }
    const userName = ref("Guest");
    const store = useStore();
    const { locale } = useI18n();

    onBeforeMount(() => {
      getCurrency();
      // const userInfoString = localStorage.getItem("user_Info");
      const userInfo = JSON.parse(localStorage.getItem("user_Info"))?.userAuth;
      if (userInfo && userInfo.name) {
        userName.value = userInfo.name;
      }
    });

    const selectCurrency = async (item) => {
      store.dispatch("setSelectedCurrency", item.currency_name);
      currency_selected.value = item.currency_name;
    };

    const selectLanguage = (item) => {
      locale.value = item.value;
      languageSelected.value = item;
      language.value = false;
      localStorage.setItem("selectedLanguage", JSON.stringify(item));
    };

    const getCurrency = async () => {
      try {
        store.dispatch("getCurrency").then(() => {
          currencies.value = store.getters.currency;
          currency_selected.value = store.getters.currency[0].currency_name;
        });
      } catch (error) {
        console.error("Error fetching offers:", error);
      }
    };

    return {
      dialog,
      userName,
      currencies,
      currency_selected,
      selectCurrency,
      selectLanguage,
      languageSelected,
      language,
      languages,
      locale,
    };
  },
};
</script>

<style scoped>
.containerHeader {
  font-family: "Cairo", sans-serif;
  display: flex;
  align-items: center;
  background-color: #003b7d;
  height: 54px;
}
.rtl {
  direction: rtl;
}
.content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 70%;
  margin: auto;
  color: #fff;
}
.left-header {
  display: flex;
  flex-direction: row-reverse;
  gap: 10px;
  align-items: center;
  width: 55%;
}
.fa-headset,
.fa-dollar-sign {
  margin-left: 1rem;
  margin-right: 1rem;
  line-height: 30px;
  text-align: center;
  width: 30px;
  height: 30px;
  border: #fff 1px solid;
  border-radius: 50%;
}
.fa-whatsapp {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  line-height: 27px;
  text-align: center;
  width: 30px;
  height: 30px;
  border: #fff 1px solid;
  border-radius: 50%;
}

.fa-headset,
.fa-dollar-sign {
  margin: 0;
}
.language {
  display: flex;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
}
.language-containerHeader {
  text-align: center;
  border: #fff 1px solid;
  border-radius: 50%;
  cursor: pointer;
}
.account {
  border: #fff 1px solid;
  border-radius: 20px;
  height: 25px;
  padding: 0.2rem 1rem;
  font-size: small;
}
.signIn {
  font-size: small;
}
.whatsapp {
  /* float: right; */
}
@media (max-width: 1350px) {
  .content {
    width: 90%;
  }
}
@media (max-width: 715px) {
  .containerHeader {
    display: none;
  }
}
.modal-containerHeader {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  padding: 1rem;
  margin-top: 1rem;
}
.currency-card {
  /* background-color: #efefef; */
  padding: 1rem 1rem;
  width: 15rem;
  display: flex;
  justify-content: start;
  align-items: center;
  cursor: pointer;
  border-radius: 7px;
  gap: 10px;
  box-shadow: none;
  border: 1px solid #ddd;
}
.currency-card:hover {
  background-color: #003b7d;
  color: #fff;
  transition: linear 0.5s;
}
.language-card {
  background-color: #efefef;
  padding: 1rem 1.7rem;
  width: 8rem;
  gap: 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  border-radius: 7px;
}
.language-card:hover {
  background-color: #003b7d;
  color: #fff;
  transition: linear 0.5s;
}
.language-card-selected {
  background-color: #003b7d;
  padding: 1rem 1.7rem;
  width: 8rem;
  gap: 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  color: #fff;
  border-radius: 7px;
}
.currency-card-selected {
  background-color: #003b7d;
  padding: 1rem 1.7rem;
  width: 5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  color: #fff;
  border-radius: 7px;
}
</style>
