<template>
  <div>
    <div class="Hotelupper-container2" v-if="showHotelOpptions">
      <HotelImages :hotelsData="hotelsInfo" />
      <HotelImagesSideBar :hotelsData="hotelsInfo" />
    </div>
  </div>
</template>

<script>
import HotelImages from "./HotelUpperSectionShowComponents/HotelImages.vue";
import HotelImagesSideBar from "./HotelUpperSectionShowComponents/HotelImagesSideBar.vue";

export default {
  /* eslint-disable */
  components: {
    HotelImages,
    HotelImagesSideBar,
  },
};
</script>
<script setup>
import { onMounted } from "vue";
import { ref, defineProps } from "vue";

// Define props directly within the script setup
const props = defineProps(["hotelsData"]);
const hotelsInfo = ref({});
const showHotelOpptions = ref(false);

// Define the showHotel function
const showHotel = async () => {
  try {
    hotelsInfo.value = props.hotelsData;
    showHotelOpptions.value = true;
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

// Call the showHotel function when the component is mounted
onMounted(showHotel);
</script>

<style>
.Hotelupper-container2 {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-start;
  align-items: center;
  color: #000;
  width: 70%;
  margin: auto;
  gap: 20px;
  margin-top: 10px;
}
@media (max-width: 1050px) {
  .Hotelupper-container2 {
    display: flex;
    flex-direction: column;
    width: 90%;
  }
}
</style>
