<template>
  <div class="HotelImage-container shadow" :dir="locale === 'ar' || locale === 'kr' ? 'rtl' : 'ltr'">
    <SearchBoxHotelPage class="mb-2" />
    <div>
      <div class="hotel-map">
        <template v-if="isMapReady">
          <Map
            :center="center"
            :zoom="10"
            style="width: 100%; height: 255px"
          >
            <Marker :position="center"></Marker>
          </Map>
        </template>
        <template v-else>
          <div class="location-unavailable">
            {{ $t('message.locationUnavailable') }}
          </div>
        </template>
      </div>
      <button
        v-if="!isMobile && isMapReady"
        @click="dialog = true"
        type="button"
        class="font-medium rounded-full text-sm px-5 py-2 text-#003b7d text-center bg-blue-500 text-white focus:outline-none btn-map"
      >
        {{ $t('message.viewLocationOnMap') }}
      </button>
      <div class="text-center pa-4">
        <v-dialog v-if="!isMobile && isMapReady" v-model="dialog" width="auto">
          <v-card width="1000">
            <v-card-title class="d-flex justify-space-between align-center">
              <v-btn icon="mdi-close" variant="text" @click="dialog = false"></v-btn>
            </v-card-title>
            <div class="modal-container">
              <div class="modal-right">
                <Map
                  :center="center"
                  :zoom="10"
                  style="width: 100%; height: 650px"
                >
                  <Marker :position="center"></Marker>
                </Map>
              </div>
            </div>
          </v-card>
        </v-dialog>
      </div>
      <div class="rating-section2 bg-white">
        <button
          type="button"
          style="margin: auto"
          class="font-medium mb-1 rounded-full text-sm px-10 py-2 bg-blue-800 text-white text-center hover:bg-blue-700 focus:outline-none"
          @click="hotelClickUp"
        >
          {{ $t('message.bookNow') }}
        </button>
      </div>
    </div>
  </div>
</template>
<script >
import SearchBoxHotelPage from "./SearchBoxHotelPage.vue";
export default {
  /* eslint-disable */
  components: {
    SearchBoxHotelPage,
  },
};
</script>
<script setup>
import { ref, defineProps, onMounted, watch } from "vue";
import { useI18n } from "vue-i18n";
import { Map, Marker } from '@fawmi/vue-google-maps';
import { useBreakpoints } from "@vueuse/core";

const { locale } = useI18n();
const props = defineProps(["hotelsData"]);
const hotelsInfo = ref({});
const center = ref({ lat: 0, lng: 0 }); // Default coordinates
const dialog = ref(false);
const isMapReady = ref(false);
const breakpoints = useBreakpoints({
  mobile: 0,
  tablet: 768,
  desktop: 1024,
});
const isMobile = breakpoints.smaller("tablet");

const parseLatLng = (lat, lng) => {
  const parsedLat = parseFloat(lat);
  const parsedLng = parseFloat(lng);
  return {
    lat: isNaN(parsedLat) ? 0 : parsedLat,
    lng: isNaN(parsedLng) ? 0 : parsedLng,
  };
};

const showHotel = async () => {
  try {
    hotelsInfo.value = props.hotelsData;
    const { lat, lng } = parseLatLng(hotelsInfo.value.lat, hotelsInfo.value.long);
    if (lat && lng) {
      center.value = { lat, lng };
      isMapReady.value = true;
    } else {
      isMapReady.value = false;
    }
  } catch (error) {
    console.error("Error fetching data:", error);
    isMapReady.value = false;
  }
};

const hotelClickUp = () => {
  window.scrollTo(0, 700);
};

watch(
  () => props.hotelsData,
  (newVal) => {
    if (newVal && newVal.lat && newVal.long) {
      hotelsInfo.value = newVal;
      const { lat, lng } = parseLatLng(newVal.lat, newVal.long);
      if (lat && lng) {
        center.value = { lat, lng };
        isMapReady.value = true;
      } else {
        isMapReady.value = false;
      }
    } else {
      isMapReady.value = false;
    }
  },
  { immediate: true }
);

onMounted(showHotel);
</script>

<style scoped>
.HotelImage-container {
  font-family: "Cairo", sans-serif;
  margin: auto;
  border-radius: 10px;
  padding: 5px 10px;
}
.hotel-map {
  height: 255px;
  border-radius: 10px;
  background-color: #fff;
  padding: 1px;
}
.location-unavailable {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: #003b7d;
  font-size: 20px;
  font-weight: 600;
}
.hotel-map iframe {
  height: 100%;
  width: 100%;
  border-radius: 10px;
  position: relative;
}
.btn-map {
  position: relative;
  top: -50px;
  display: block;
  width: 80%;
  margin: auto;
}
.rating-section2 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: #f7f7f7;
  padding: 5px 12px;
  border-radius: 15px;
}

.modal-container {
  display: flex;
  gap: 10px;
  padding: 20px;
}
.modal-right {
  width: 100%;
}
.modal-right iframe {
  border-radius: 20px;
}

/* Responsive Design */
@media (max-width: 767px) {
  .HotelImage-container {
    width: 95%;
  }
  .btn-map {
    top: -30px;
    width: 100%;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .HotelImage-container {
    width: 85%;
  }
  .btn-map {
    top: -40px;
    width: 90%;
  }
}

@media (min-width: 1024px) {
  .HotelImage-container {
    width: 70%;
  }
  .btn-map {
    top: -50px;
    width: 80%;
  }
}
</style>
