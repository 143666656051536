<template>
  <div class="w-full">
    <div style="display: flex; flex-wrap: wrap; gap: 10px; margin-top: 1rem; padding:0.25rem">
        <span
          v-for="(item, index) in hotelsInfo.all_services"
          :key="index"
          style="background-color: #fafafa; font-size: 16px"
          class="rounded-full text-center px-4"
        >
          <i :class="item.icon"></i>
          <span class="mx-2">{{ item[`${locale}_name`] }}</span>
        </span>
      </div>
  </div>
</template>

<script>
import { useI18n } from 'vue-i18n';

export default {
  name: "HotelHeader",
  // Your component logic goes here
  props: {
    hotelsInfo: {}
  },
  data() {
    return {
    };
  },
  setup(){
    const {locale} = useI18n();

    return {
      locale
    }
  }
};
</script>

<style scoped>
.container-header {
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
  /* align-items: center; */
  color: #000;
  /* padding: 15px 15px; */
  position: relative;
}
.container {
  margin: auto;
  font-family: "Cairo", sans-serif;
  /* width: 70%; */
  margin-top: 1.5rem;
  direction: rtl;
}
.rating-count {
  font-size: 12px;
  margin-right: 3px;
}
.start-count {
  /* font-size: 16px; */
  background-color: #fafafa;
  padding: 2px 8px;
  align-items: center;
  gap: 1px;
}
</style>
