<template>
  <div class="container" :dir="locale === 'ar' || locale === 'kr' ? 'rtl' : 'ltr'">
    <div class="shadow">
      <div class="container-header">
        <div class="right-side-header rating-count">
          <span style="color: orange">{{ $t("message.home") }}</span> \
          <span style="color: orange">{{ $t("message.hotelList") }}</span>
        </div>
        <div class="left-side-header">
          <div>
            <span class="rating-count rounded-full text-center start-count">
              <v-icon class="text-orange" size="14" icon="mdi-star"></v-icon
              ><span>4</span>
            </span>
            <span class="start-count mr-2 rounded-full">
              <v-icon
                class="text-orange"
                size="14"
                icon="mdi-star"
                v-for="(item, index) in hotelsInfo.stars"
                :key="index"
              ></v-icon>
              <span class="rating-count">{{ hotelsInfo.stars }}/5</span>
            </span>
          </div>
        </div>
      </div>
      <div class="hotel-and-address-header" :dir="locale === 'ar' || locale === 'kr' ? 'ltr' : 'ltr'">
        <div>
          <v-icon class="mx-2">mdi-map-marker-radius</v-icon>
          <span>
            {{ hotelsInfo.address ? hotelsInfo.address : "No Available location" }}
          </span>
        </div>
        <div class="hotel-title text-blue-800">
          {{ hotelsInfo.title }}
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted } from "vue";
import { ref, defineProps } from "vue";
import { useI18n } from "vue-i18n";

const { locale } = useI18n();
const props = defineProps(["hotelsData"]);
const hotelsInfo = ref({});

const showHotel = async () => {
  try {
    hotelsInfo.value = props.hotelsData;
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

onMounted(showHotel);
</script>

<style scoped>
.container {
  margin: auto;
  font-family: "Cairo", sans-serif;
  width: 90%;
  margin-top: 1.5rem;
  direction: rtl;
}

.container-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: #000;
  padding: 15px 15px;
  position: relative;
}
.hotel-and-address-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: #000;
  padding: 15px 15px;
  position: relative;
}
.hotel-title {
  font-size: 20px;
  font-weight: 600;
}

.rating-count {
  font-size: 12px;
  margin-right: 3px;
}

.start-count {
  background-color: #fafafa;
  padding: 2px 8px;
  align-items: center;
  gap: 1px;
}

@media (max-width: 767px) {
  .container {
    width: 95%;
  }
  .container-header,
  .hotel-and-address-header {
    flex-direction: column;
    gap: 10px;
    padding: 10px 15px;
    text-align: center;
    align-items: center;
  }
  .hotel-and-address-header {
    flex-direction: column-reverse;
  }
  .left-side-header {
    margin-top: 10px;
    width: 100%;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .container {
    width: 85%;
  }
  .container-header,
  .hotel-and-address-header {
    flex-direction: column;
    direction: rtl;
    gap: 10px;
    text-align: center;
    align-items: center;
  }
  .hotel-and-address-header {
    flex-direction: column-reverse;
  }
  .left-side-header {
    margin-top: 10px;
    /* display: flex;
    justify-content: space-between; */
    width: 100%;
  }
}

@media (min-width: 1024px) {
  .container {
    width: 70%;
  }
}
</style>
