<template>
  <div :dir="locale === 'ar' || locale === 'kr' ? 'rtl' : 'ltr'" class="container shadow">
    <div class="content">
      <div class="slider-text-top">{{ $t('message.faqTitle') }}</div>
      <div class="slider-text-bottom">{{ $t('message.faqDescription') }}</div>
      <div class="header-text-bottom">
        <button
          type="button"
          class="text-white focus:outline-none focus:ring-4 focus:ring-blue-300 font-medium rounded-full text-sm px-10 py-2.5 text-center"
        >
          {{ $t('message.goToCustomerService') }}
        </button>
        <button
          type="button"
          class="text-blue rounded-full text-sm px-10 py-2.5 go-to-contact-us text-center"
        >
          {{ $t('message.contactUsViaWhatsApp') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { useI18n } from 'vue-i18n';

export default {
  setup() {
    const { t, locale } = useI18n();
    return { t, locale };
  },
};
</script>

<style scoped>
.container {
  font-family: "Cairo", sans-serif;
  position: relative;
  top: -70px;
  width: 90%;
  margin: auto;
  border-radius: 10px;
  align-items: flex-start;
  background-color: #fff;
  height: auto;
  z-index: 10;
  display: flex;
  flex-direction: column;
  padding: 20px;
}
.content {
  z-index: 20;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  margin: auto;
  gap: 25px;
}
.slider-text-top {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffb325;
  font-size: 1.5em;
  text-align: center;
  z-index: 10;
}
.slider-text-bottom {
  width: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  z-index: 10;
}
.header-text-bottom {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;
}
button {
  background-color: #003b7d;
  font-weight: 600;
}
.go-to-contact-us {
  border: 2px solid #003b7d;
  color: #003b7d;
  background-color: #fff;
  transition: all 0.3s ease-in-out;
}

/* Responsive Design */
@media (min-width: 768px) {
  .container {
    width: 80%;
    padding: 30px;
  }
  .slider-text-top {
    font-size: 1.8em;
  }
  .slider-text-bottom {
    width: 60%;
    font-size: 1.2em;
  }
  .header-text-bottom button {
    font-size: 1em;
    padding: 10px 20px;
  }
}

@media (min-width: 1024px) {
  .container {
    width: 70%;
    padding: 40px;
  }
  .slider-text-top {
    font-size: 2em;
  }
  .slider-text-bottom {
    width: 50%;
    font-size: 1.5em;
  }
  .header-text-bottom button {
    font-size: 1.2em;
    padding: 12px 24px;
  }
}
</style>
