<template>
  <div
    class="container"
    :dir="locale === 'ar' || locale === 'kr' ? 'rtl' : 'ltr'"
  >
    <div class="card1-container">
      <v-form style="margin-top: 2rem" @submit.prevent="submitBooking">
        <div class="container-header">
          <div class="typing" :dir="isRTL ? 'rtl' : 'ltr'">
            <span class="typing-header">{{
              $t("message.enterYourDetails")
            }}</span>
          </div>
        </div>
        <div class="inputs-show shadow">
          <div class="input-field-section-info">
            <div class="input-field-info">
              <div>
                {{ $t("message.fullname") }}
                <span style="color: red; font-size: large">*</span>
              </div>
              <v-text-field
                v-model="fullName"
                placeholder=" "
                density="compact"
                variant="outlined"
                :rules="required"
                @change="updateField('full_name', fullName)"
              ></v-text-field>
            </div>
            <div class="input-field-info">
              <div>
                {{ $t("message.phone") }}
                <span style="color: red; font-size: large">*</span>
              </div>
              <v-text-field
                v-model="phone"
                placeholder=" "
                density="compact"
                variant="outlined"
                :rules="required"
                @change="updateField('phone', phone)"
              ></v-text-field>
            </div>
          </div>

          <div class="input-field-section-info">
            <div class="input-field-info">
              <div>
                {{ $t("message.emailAddress") }}
                <span style="color: red; font-size: large">*</span>
              </div>
              <v-text-field
                v-model="email"
                placeholder=" "
                density="compact"
                variant="outlined"
                @change="updateField('email', email)"
                :rules="isValidEmail"
              ></v-text-field>
            </div>
            <div class="input-field-info">
              <div>
                {{ $t("message.country") }}
                <span style="color: red; font-size: large">*</span>
              </div>
              <v-text-field
                v-model="country"
                placeholder=" "
                density="compact"
                variant="outlined"
                :rules="required"
                @change="updateField('country', country)"
              ></v-text-field>
            </div>
          </div>

          <!-- Hour and Minute Selectors for Arrival Time -->
          <div class="input-field-section-info">
            <div class="input-field-info">
              <div>
                {{ $t("message.arrivalDate") }}
                <span style="color: red; font-size: large">*</span>
              </div>
              <div style="display: flex; gap: 10px">
                <!-- Hour Selector -->
                <v-select
                  v-model="selectedHour"
                  :items="hours"
                  density="compact"
                  dense
                  variant="outlined"
                  :rtl="isRTL"
                  :rules="required"
                >
                  <template v-slot:prepend> {{ $t("message.hour") }} </template>
                </v-select>

                <!-- Minute Selector -->
                <v-select
                  density="compact"
                  v-model="selectedMinute"
                  :items="minutes"
                  dense
                  variant="outlined"
                  :rtl="isRTL"
                  :rules="required"
                >
                  <template v-slot:prepend>
                    {{ $t("message.minutes") }}
                  </template>
                </v-select>
              </div>
            </div>
          </div>
        </div>
        <div>
          <v-card class="mt-1 card-style">
            <div class="button-container">
              <button
                type="submit"
                class="text-white bg-blue-600 hover:bg-blue-800 font-large rounded-full px-6 py-2 text-md text-center"
              >
                {{ $t("message.completeBooking") }}
              </button>
            </div>
          </v-card>
        </div>
      </v-form>
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";

// Initialize store and route
const store = useStore();
const route = useRoute();
const { locale, t } = useI18n();

const isRTL = computed(() => locale !== 'en')

// Form fields
const fullName = ref("");
const email = ref("");
const phone = ref("");
const country = ref("");

// Hour and Minute selectors
const selectedHour = ref(null); // Stores selected hour
const selectedMinute = ref(null); // Stores selected minute

// Hours and minutes list
const hours = Array.from({ length: 24 }, (_, i) =>
  i.toString().padStart(2, "0")
);
const minutes = Array.from({ length: 60 }, (_, i) =>
  i.toString().padStart(2, "0")
);

const required = [
  (value) => {
    if (value?.length >= 1) return true;

    return t("message.required_error_message");
  },
];
const isValidEmail = [
  (value) => {
    if (value?.length <= 1) {
      return t("message.required_error_message");
    }
    if(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value)) {
      return true
    }

    return t('message.invalid_email')

  },
];

// Computed property to combine selected hour and minute
const arrivalTime = computed(() => {
  if (selectedHour.value !== null && selectedMinute.value !== null) {
    return `${selectedHour.value}:${selectedMinute.value}`;
  }
  return null;
});

// Fetch reservation_id from the route params
const reservationId = route.params.id;

// Update form fields in Vuex
const updateField = (field, value) => {
  store.dispatch("updateBookingField", { field, value });
};

// Submit booking data to API, including combined arrival time
const submitBooking = async (event) => {
  if (!(await event.valid)) return;
  try {
    // Update the arrival_time field with the combined hour and minute
    updateField("arrival_time", arrivalTime.value);

    await store.dispatch("confirmBooking", reservationId).then((res) => {
      // Assuming res.data.payment_url.href contains the URL you want to redirect to
      if (
        res &&
        res.data.data &&
        res.data.data.payment_url &&
        res.data.data.payment_url.href
      ) {
        // Redirect to the payment URL
        window.location.href = res.data.data.payment_url.href;
      } else {
        console.error("Payment URL not found in response:", res);
      }
    });

    console.log("Booking confirmed successfully.");
  } catch (error) {
    console.error("Error confirming booking:", error);
  }
};
</script>

<style scoped>
.container-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: #000;
  padding: 10px 10px;
  position: relative;
}
.container {
  margin: auto;
  font-family: "Cairo", sans-serif;
  width: 70%;
  margin-top: 1.5rem;
  direction: rtl;
  margin-bottom: 4rem;
}
.page-divider {
  display: flex;
  gap: 20px;
  width: 100%;
}
.page-divider .card1-container {
  width: 100%;
}
.inputs-show {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem 2rem;
}
.input-field-section-info {
  display: flex;
  gap: 2rem;
  width: 100%;
}
.input-field-info {
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
  width: 45%;
}
.input-field-info-email {
  width: 100%;
}
.card-style {
  padding: 1.5rem;
}
.button-container {
  display: flex;
  justify-content: center;
  padding: 1.5rem 0;
}
.shadow {
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}
@media (max-width: 768px) {
  .container {
    width: 90%;
  }
  .page-divider {
    flex-direction: column;
  }
  .input-field-section-info {
    flex-direction: column;
    gap: 1rem;
  }
  .input-field-info {
    width: 100%;
  }
}
</style>
