<template>
  <div class="w-full pb-10">
    <div class="container-header">
      <div class="typing" dir="rtl">
        <!-- <span class="typing-header mb-1">مرافق متوفرة في فندق</span>
        <span class="typing-pargraph mt-1"
          >أعرف اكثر عن مكان الاقامة من خلال اسئلة المسافرين</span
        > -->
      </div>
    </div>
    <HotelItemsServices class="mb-3 px-10" :hotelsInfo="hotelsInfo" />
    <div class="masonry px-10">
      <HotelChecksServices
        v-for="(item, index) in hotelFeatures"
        :key="index"
        :title="item[`${locale}_name`]"
        :icon="item.icon"
        :services="item.features"
        class="masonry-item"
      />
    </div>
  </div>
</template>

<script>
import HotelItemsServices from "../ShowHotelComponents/HotelItemsServices.vue";
import HotelChecksServices from "../ShowHotelComponents/HotelChecksServices.vue";
import { useI18n } from "vue-i18n";

export default {
  name: "HotelThingsProvided",
  props: {
    hotelsInfo: {},
  },
  /* eslint-disable */
  components: {
    HotelChecksServices,
    HotelItemsServices,
  },
  setup() {
    const { locale } = useI18n();
    return {
      locale,
    };
  },
  computed: {
    hotelFeatures(){
      return this.hotelsInfo.features?.filter(f => f.features.length > 0) || []
    }
  }
};
</script>

<style>
.masonry {
  column-count: 3;
  column-gap: 1rem;
}

.masonry-item {
  break-inside: avoid;
  margin-bottom: 1rem;
}

.main-container {
  width: 70%;
  margin: auto;
  font-family: "Cairo", sans-serif;
  margin-top: 3rem;
}
.container-header {
  margin: auto;
}
.typing {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
}
.typing-header {
  font-size: 26px;
  color: #ffb325;
  font-weight: bold;
}
.typing-pargraph {
  font-size: 14px;
  color: #000;
}
.hotel-services {
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 5px;
  margin-bottom: 3rem;
}
</style>
