<template>
  <div>
    <Header />
    <HamburgerMenu />
    <MainSlider />
    <SearchBox />
    <template v-for="slide in sliders" :key="slide.id">
      <OffersNow
        v-if="slide.slider_type === 0"
        :slide="slide"
        :loading="loading"
      />
      <ExploreCounteryGrid
        v-if="slide.slider_type === 1"
        class="Explore-Countery-Grid"
        :slide="slide"
        :loading="loading"
      />
      <Discounts
        v-if="slide.slider_type === 2"
        :slide="slide"
        :data="discounts"
        :loading="loading"
      />
      <RecommendedHotels
        v-if="slide.slider_type === 3"
        :slide="slide"
        :loading="loading"
      />
      <PerfectStay
        v-if="slide.slider_type === 5"
        :slide="slide"
        :loading="loading"
      />
      <PlacesNear
        v-if="slide.slider_type === 4"
        :slide="slide"
        :loading="loading"
      />
    </template>
    <Footer />
    <CopyRightComponent />
  </div>
</template>

<script>
import Header from "../components/Header.vue";
import HamburgerMenu from "../components/HamburgerMenu.vue";
import MainSlider from "../components/MainSlider.vue";
import SearchBox from "../components/SearchBox.vue";
import OffersNow from "../components/OffersNow.vue";
import ExploreCounteryGrid from "../components/ExploreCounteryGrid.vue";
import Discounts from "../components/Discounts.vue";
import PlacesNear from "../components/PlacesNear.vue";
import PerfectStay from "../components/PerfectStay.vue";
import RecommendedHotels from "../components/RecommendedHotels.vue";
import Footer from "../components/Footer.vue";
import CopyRightComponent from "../components/CopyRightComponent.vue";

export default {
  mounted() {
    window.scrollTo(0, 0);
  },
  name: "MainPage",
  components: {
    Header,
    HamburgerMenu,
    MainSlider,
    SearchBox,
    OffersNow,
    ExploreCounteryGrid,
    Discounts,
    RecommendedHotels,
    PlacesNear,
    // PerfectStay,
    Footer,
    CopyRightComponent,
  },
};
</script>

<script setup>
import { ref, onMounted, watch } from "vue";
import { useStore } from "vuex";

const store = useStore();
const loading = ref(true);
const sliders = ref([]);
const offers = ref([]);
const discounts = ref([]);

// Function to get sliders, offers, and discounts
const getSlidersOffers = async () => {
  try {
    console.log("Fetching sliders...");
    await store.dispatch("getSliders").then((res) => {
      sliders.value = res.data.sort((a, b) => a.sort_order - b.sort_order);
    });
    await store.dispatch("getOffer").then(() => {
      offers.value = store.getters.offers.data;
    });
    await store.dispatch("getDiscounts").then(() => {
      discounts.value = store.getters.discounts.data;
    });
  } catch (error) {
    console.error("Error fetching offers:", error);
  } finally {
    loading.value = false;
  }
};

// Watch for changes in userInfo from Vuex
watch(
  () => store.getters.userInfo,
  async (newUserInfo) => {
    if (newUserInfo && newUserInfo.token) {
      // User info with token exists, proceed with fetching data
      console.log("User info and token available, fetching sliders...");
      await getSlidersOffers();
    } else {
      // Handle case where user info or token doesn't exist
      console.warn("User information not found.");
      // Optionally, redirect to login page if required
      // router.push("/login");
    }
  },
  { immediate: true } // This ensures the watch triggers immediately on mount
);

onMounted(() => {
  // Ensure token is available and user info is complete before fetching sliders
  const userInfo = store.getters.userInfo;
  if (userInfo && userInfo.token) {
    getSlidersOffers();
  }
});
</script>

<style scoped>
@media (max-width: 832px) {
  .Explore-Countery-Grid {
    display: none;
  }
}
</style>
