<template>
  <div class="w-full rounded px-2 py-2" dir="rtl">
    <div>
      <span style="font-size: 18px" class="font-bold">{{ title }}</span>
    </div>
    <div class="flex flex-col">
      <div v-for="(item, index) in services" :key="index">
        <v-icon class="ml-2 text-blue" size="14" icon="mdi-check"></v-icon>
        <span style="font-size: 14px">{{ item[`${locale}_name`] }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";

export default {
  name: "HotelServicesProvided",
  props: {
    title: String,
    icon: String,
    services: Array,
  },
  /* eslint-disable */
  components: {},
  setup() {
    const { locale } = useI18n();

    return {
      locale,
    };
  },
};
</script>

<style>
.main-container-services {
  padding: 3rem;
  /* margin: auto; */
  font-family: "Cairo", sans-serif;
  margin-top: 1rem;
}
.services-list {
  margin: 1rem 0rem;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}
.services-list div {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0.25rem;
}
</style>
