<template>
  <div :class="[{ rtl: locale === 'ar' || locale === 'kr' }, 'container']">
    <div class="container-header shadow">
      <div class="right-side-header">
        <span style="color: orange">{{ $t("message.home") }}</span> \
        {{ $t("message.hotelList") }}
      </div>
      <div class="left-side-header">
        <div class="text-left-side-header">
          <span>{{ $t("message.viewMode") }}</span>
        </div>
        <div class="image-icons">
          <span
            @click="toggleView('vertical')"
            :class="[listShowType ? 'listShowTypeDisabled' : '']"
          >
            <v-icon size="large" icon="mdi-view-sequential"></v-icon>
          </span>
          <span
            @click="toggleView('horizontal')"
            :class="[!listShowType ? 'listShowTypeDisabled' : '']"
          >
            <v-icon size="large" icon="mdi-view-column"></v-icon>
          </span>
        </div>
        <v-locale-provider rtl>
          <v-select
            density="compact"
            rtl
            :label="$t('message.sortBy')"
            :items="[
              'California',
              'Colorado',
              'Florida',
              'Georgia',
              'Texas',
              'Wyoming',
            ]"
            variant="outlined"
          ></v-select>
        </v-locale-provider>

        <v-locale-provider rtl>
          <v-select
            density="compact"
            rtl
            :label="$t('message.itemsPerPage')"
            :items="[
              'California',
              'Colorado',
              'Florida',
              'Georgia',
              'Texas',
              'Wyoming',
            ]"
            variant="outlined"
          ></v-select>
        </v-locale-provider>
      </div>
    </div>

    <div class="show-list-container mt-6">
      <div
        v-if="hotelsData.length && finishSearch"
        class="show-list-container2"
      >
        <div v-if="listShowType" class="hotel-list mt-2">
          <div v-for="hotel in hotelsData" :key="hotel.id">
            <VerticalHotelListing
              @click="hotelClick(hotel.id)"
              :hotel="hotel"
            />
          </div>
        </div>
        <div v-if="!listShowType" class="hotel-list mt-2">
          <HorizontalHotelListing
            v-for="hotel in hotelsData"
            :key="hotel.id"
            @click="hotelClick(hotel.id)"
            :hotel="hotel"
          />
        </div>
      </div>
      <div
        v-if="!hotelsData.length && finishSearch"
        class="show-list-container2"
      >
        <v-card class="hotel-not-found">
          <div>
            <v-icon
              class="text-orange"
              size="80"
              icon="mdi-alert-circle"
            ></v-icon>
          </div>
          <div style="font-size: 28px; color: green">
            {{ $t("message.noHotels") }}
          </div>
          <div>{{ $t("message.tryDifferentSearch") }}</div>
        </v-card>
      </div>
      <div
        v-if="!hotelsData.length && !finishSearch"
        class="show-list-container2"
      >
        <div class="hotel-not-found2">
          <v-skeleton-loader type="card"></v-skeleton-loader>
        </div>
      </div>
      <div class="side-filters shadow">
        <div class="main-filters">
          <div class="range-slider-container">
            <div
              class="mt-5 mb-5"
              style="display: flex; justify-content: space-between"
            >
              <span>{{ $t("message.filterByPrice") }}</span>
              <span>
                <v-icon size="large" icon="mdi-menu-down"></v-icon>
              </span>
            </div>
            <v-range-slider
              color="blue"
              v-model="range"
              :max="maxValue"
              :min="minValue"
              :step="1"
              hide-details
              class="align-center"
            >
            </v-range-slider>
            <div
              style="display: flex; justify-content: space-between; gap: 20px"
            >
              <v-text-field
                v-model="range[1]"
                hide-details
                hide-spin-buttons
                single-line
                type="number"
                prefix="$"
                variant="outlined"
                density="compact"
              ></v-text-field>
              <div>-</div>
              <v-text-field
                v-model="range[0]"
                hide-details
                hide-spin-buttons
                single-line
                prefix="$"
                type="number"
                variant="outlined"
                density="compact"
              ></v-text-field>
            </div>
          </div>
          <div style="display: flex; justify-content: space-between">
            <span>{{ $t("message.mainFilters") }}</span>
            <span>
              <v-icon size="large" icon="mdi-menu-down"></v-icon>
            </span>
          </div>
          <div class="mr-5 mt-5">
            <div class="filters">
              <span>{{ $t("message.rate") }}</span>
              <span class="rateSelectSpan">
                <v-icon
                  v-for="n in 5"
                  :key="n"
                  :class="[filters.rate >= n ? 'text-orange' : 'text-grey']"
                  @click="setRate(n)"
                  size="14"
                  icon="mdi-star"
                ></v-icon>
              </span>
            </div>
            <div class="filters">
              <span>{{ $t("message.starsAcc") }}</span>
              <span class="starsSelectSpan">
                <v-icon
                  v-for="n in 5"
                  :key="n"
                  :class="[filters.stars >= n ? 'text-orange' : 'text-grey']"
                  @click="setStars(n)"
                  size="14"
                  icon="mdi-star"
                ></v-icon>
              </span>
            </div>
            <div
              class="filters"
              v-for="(item, index) in filterOptions"
              :key="index"
            >
              <span>{{ $t(item.label) }}</span>
              <span class="checkboxSpan">
                <v-checkbox v-model="filters[item.value]" />
              </span>
            </div>
          </div>
        </div>
      </div>
      <div
        class="text-center pagination-of-hotels"
        style="direction: ltr"
        @click="getHotels"
        v-if="hotelsData.length"
      >
        <v-pagination
          :length="last_page"
          active-color="blue"
          show-first-last-page
          :total-visible="5"
          rounded="rounded"
          v-model="page"
        ></v-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, watch } from "vue";
import { useStore } from "vuex";
import VerticalHotelListing from "./VerticalHotelListing.vue";
import HorizontalHotelListing from "./HorizontalHotelListing.vue";
import { useRoute, useRouter } from "vue-router";
import { useI18n } from "vue-i18n";

export default {
  name: "FaqMainList",
  components: {
    VerticalHotelListing,
    HorizontalHotelListing,
  },
  setup() {
    const { locale } = useI18n();
    const store = useStore();
    const hotelsData = ref([]);
    const todayDate = ref("");
    const finishSearch = ref(false);
    const tomorrowDate = ref("");
    const listShowType = ref(false);
    const route = useRoute();
    const router = useRouter();
    const last_page = ref("");
    const per_page = ref("");
    const page = ref(1);

    const governorate = route.query.governorate;
    const checkin = route.query.checkin;
    const checkout = route.query.checkout;
    const room_no = route.query.room_no;
    const adult_no = route.query.adult_no;
    const children_no = route.query.children_no;

    const filters = ref({
      rate: 0,
      stars: 0,
      total_views: false,
      long: false,
      lat: false,
      start_price: false,
      end_price: false,
      is_active: false,
      smoking_policy: false,
      has_pool: false,
      has_gym: false,
      has_restaurant: false,
      has_bar: false,
      has_brakfast: false,
      reserve_without_credit: false,
      has_sauna: false,
      has_room_service: false,
      has_sweet_rooms: false,
      has_free_parking: false,
      show_in_website: false,
    });

    const range = ref([0, 500]);
    const minValue = ref(0);
    const maxValue = ref(1000);

    const setRate = (value) => {
      filters.value.rate = value;
    };

    const setStars = (value) => {
      filters.value.stars = value;
    };

    const filterOptions = [
      { label: "message.is_active", value: "is_active" },
      { label: "message.smoking_policy", value: "smoking_policy" },
      { label: "message.has_pool", value: "has_pool" },
      { label: "message.has_gym", value: "has_gym" },
      { label: "message.has_restaurant", value: "has_restaurant" },
      { label: "message.has_bar", value: "has_bar" },
      { label: "message.has_brakfast", value: "has_brakfast" },
      {
        label: "message.reserve_without_credit",
        value: "reserve_without_credit",
      },
      { label: "message.has_sauna", value: "has_sauna" },
      { label: "message.has_room_service", value: "has_room_service" },
      { label: "message.has_sweet_rooms", value: "has_sweet_rooms" },
      { label: "message.has_free_parking", value: "has_free_parking" },
    ];

    const getHotels = async () => {
      try {
        const filterParams = {
          ...Object.fromEntries(
            Object.entries(filters.value).map(([key, value]) => [
              key,
              value === false || value === null ? 0 : value,
            ])
          ),
          start_price: range.value[0],
          end_price: range.value[1],
        };

        await store.dispatch("getHotels", {
          take: 9,
          page: page.value,
          governorate: governorate,
          checkin: checkin ? checkin : todayDate.value,
          checkout: checkout ? checkout : tomorrowDate.value,
          room_number: room_no,
          adult_number: adult_no,
          children_number: children_no,
          currency: store.getters.currency_selected,
          ...filterParams,
        });
        finishSearch.value = true;
        hotelsData.value = store.getters.hotels.data;
        last_page.value = store.getters.hotels.last_page;
        per_page.value = store.getters.hotels.per_page;
      } catch (error) {
        console.error("Error fetching hotels:", error);
      }
    };
    const debounce = (func, delay) => {
      let timeout;
      return (...args) => {
        clearTimeout(timeout);
        timeout = setTimeout(() => func(...args), delay);
      };
    };


    watch(filters, getHotels, { deep: true });
    watch(range, debounce(getHotels, 1000), { deep: true });

    onMounted(() => {
      const today = new Date();
      const year = today.getFullYear();
      const month = (today.getMonth() + 1).toString().padStart(2, "0");
      const day = today.getDate().toString().padStart(2, "0");
      todayDate.value = `${year}-${month}-${day}`;

      const tomorrow = new Date(today);
      tomorrow.setDate(tomorrow.getDate() + 1);
      const year2 = tomorrow.getFullYear();
      const month2 = (tomorrow.getMonth() + 1).toString().padStart(2, "0");
      const day2 = tomorrow.getDate().toString().padStart(2, "0");
      tomorrowDate.value = `${year2}-${month2}-${day2}`;

      getHotels();
    });

    const hotelClick = (id) => {
      router.push({
        name: "Hotel",
        query: {
          id: id,
          governorate: governorate,
          checkin: checkin,
          checkout: checkout,
          room_no: room_no,
          adult_no: adult_no,
          children_no: children_no,
        },
      });
    };

    const toggleView = (type) => {
      listShowType.value = type === "horizontal";
    };

    return {
      hotelsData,
      toggleView,
      listShowType,
      hotelClick,
      getHotels,
      last_page,
      page,
      finishSearch,
      locale,
      filters,
      filterOptions,
      setRate,
      setStars,
      range,
      minValue,
      maxValue,
    };
  },
};
</script>

<style scoped>
.container {
  margin: auto;
  font-family: "Cairo", sans-serif;
  width: 90%;
  margin-bottom: 3rem;
  direction: rtl;
}

.container-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: #000;
  padding: 0px 15px;
  position: relative;
  height: 60px;
}

.left-side-header {
  position: absolute;
  top: 15%;
  left: 1%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 15px;
  align-items: center;
  width: 60%;
  direction: rtl;
  color: #000;
}

.image-icons {
  margin-bottom: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.image-icons span {
  cursor: pointer;
  padding: 2px;
  border-radius: 20%;
  margin: 2px;
}

.text-left-side-header {
  width: 15%;
  position: relative;
}

.text-left-side-header span {
  position: absolute;
  bottom: 100%;
  left: 0;
}

.hotel-list {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  width: 100%;
  flex-wrap: wrap;
}

.show-list-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 10px;
  width: 100%;
  flex-wrap: wrap;
}

.show-list-container2 {
  width: 73%;
}

.pagination-of-hotels {
  width: 70%;
  margin-top: 1.5rem;
}

.side-filters {
  width: 25%;
  background-color: #fff;
  border-radius: 10px;
  padding: 1rem;
}

.filters {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  height: 40px;
}

.filters .checkboxSpan {
  height: 40px;
}
.filters .starsSelectSpan {
  height: 25px;
}
.filters .rateSelectSpan {
  height: 25px;
}

.text-grey {
  color: grey;
}

.text-red {
  color: red;
}

.text-orange {
  color: orange;
}

@media (max-width: 1160px) {
  .side-filters {
    display: none;
  }
  .show-list-container2 {
    width: 100%;
  }
  .left-side-header {
    display: none;
  }
  .right-side-header {
    display: none;
  }
}

.main-filters {
  display: flex;
  flex-direction: column;
  padding-bottom: 2rem;
}

.filers {
  margin-top: 1.5rem;
  display: flex;
  flex-direction: column;
  padding-bottom: 2rem;
}

.item-number {
  font-size: 12px;
  background-color: #fafafa;
  border-radius: 50%;
  width: 22px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.range-slider-container {
  padding-bottom: 2rem;
}

.icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.listShowTypeDisabled {
  color: rgb(196, 196, 196);
}

.listShowTypeDisabled:hover {
  color: rgb(151, 151, 151);
}

.hotel-not-found {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 7rem 5rem;
  align-items: center;
  gap: 2rem;
}

.hotel-not-found2 {
  width: 100%;
  padding: 5rem;
}

@media (max-width: 768px) {
  .container {
    width: 100%;
  }

  .container-header {
    flex-direction: column;
    height: auto;
  }

  .left-side-header {
    position: static;
    width: 100%;
  }

  .hotel-list {
    justify-content: center;
  }

  .pagination-of-hotels {
    width: 100%;
  }
}
</style>
