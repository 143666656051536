import { createApp } from "vue";
import App from "./App.vue";
import "@fortawesome/fontawesome-free/css/all.css";
import "@/assets/styles/style.css";
import "@/assets/styles/input.css";
import router from "./router";
import store from "./store";
import i18n from "./i18n";
// Vuetify
import "@mdi/font/css/materialdesignicons.css";
import "vuetify/dist/vuetify.min.css";
import { createVuetify } from "vuetify";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";
import { mapGetters, mapActions, mapMutations } from "vuex";
import VueGoogleMaps from "@fawmi/vue-google-maps";
import OneSignalVuePlugin from "@onesignal/onesignal-vue3";

const vuetify = createVuetify({
  components,
  directives,
  rtl: true,
  icons: {
    iconfont: "mdi", // Default is 'mdi'
  },
});

var mixin = {
  data() {
    return {
      baseUrl: "",
    };
  },
  computed: {
    ...mapGetters(["user_permissions", "users", "userInfo"]),
    getUserInfo() {
      return JSON.parse(localStorage.getItem("userInfo")) || false;
    },
  },
  methods: {
    ...mapMutations([]),
    ...mapActions(["login", "logOut", "getUsres"]),
  },
};
const app = createApp(App);

app.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyAAbuVt-6vsggyQ_GlTkhdQi00SFxthV_c",
    libraries: "places", // necessary for places input
  },
});
// Use Vue Router
app.use(router);
// use the i18n instance
app.use(i18n);
// Use Vuex store
app.use(store);

// Use Vuetify with RTL support
app.use(vuetify, {
  rtl: true,
});

app.use(OneSignalVuePlugin, {
  appId: "c64bc287-faf5-4889-a3cc-2a6d8bdfbff6",
});

app.mixin(mixin);

app.mount("#app");
