<template>
  <div :class="[{ 'rtl': locale === 'ar' || locale === 'kr' }, 'slider']">
    <div class="fade-container">
      <div class="slider-text">{{ $t('message.sliderText') }}</div>
      <div class="slider-text-bottom">{{ $t('message.sliderTextBottom') }}</div>

      <div class="overlay"></div>
      <div
        v-for="(image, index) in images"
        :key="image"
        :style="{ opacity: index === currentImage ? 1 : 0 }"
        class="fade-item"
      >
        <img :src="image" :alt="'Slideshow image ' + (index + 1)" />
      </div>
    </div>
  </div>
</template>

<script>
import { useI18n } from 'vue-i18n';

export default {
  data() {
    return {
      images: [
        require("@/assets/imgs/img3.jpg"),
        require("@/assets/imgs/img2.jpg"),
        require("@/assets/imgs/img1.jpg"),
        require("@/assets/imgs/img4.jpg"),
        require("@/assets/imgs/img6.jpg"),
      ],
      currentImage: 0,
      transitionDelay: 500, // Delay in milliseconds
    };
  },
  created() {
    setInterval(this.nextImage, 3000);
  },
  methods: {
    nextImage() {
      setTimeout(() => {
        this.currentImage = (this.currentImage + 1) % this.images.length;
      }, this.transitionDelay);
    },
  },
  setup() {
    const { locale } = useI18n();
    return { locale };
  },
};
</script>

<style scoped>
.fade-container {
  font-family: "Cairo", sans-serif;
  position: relative;
  height: 500px; /* the fixed height from the UI design */
}

.fade-item {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  transition: opacity 1s ease-in-out;
}

.fade-item img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* cover the area without losing aspect ratio */
  display: block;
}

.slider {
  width: 100%;
  overflow: hidden;
  position: relative;
}
.slider-text {
  width: 50%;
  position: absolute;
  top: 35%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  font-size: 3em;
  text-align: center;
  z-index: 10; /* Putting the text above the images */
}
.slider-text-bottom {
  width: 50%;
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  font-size: 1.5em;
  text-align: center;
  z-index: 10;
}
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to bottom,
    #003b7d,
    rgba(11, 104, 175, 0)
  ); /* Gradient from full color to transparent */
  z-index: 5; /* Putting it above the images, below the text */
}
@media (max-width: 1150px) {
  .slider-text {
    font-size: 2em;
  }
  .slider-text-bottom {
    font-size: 1em;
  }
}
@media (max-width: 600px) {
  .slider-text {
    width: 80%;
    font-size: 2em;
  }
  .slider-text-bottom {
    width: 80%;
    font-size: 1em;
  }
}
@media (max-width: 380px) {
  .slider-text {
    width: 80%;
    font-size: 1.5em;
  }
  .slider-text-bottom {
    width: 80%;
    font-size: 1em;
  }
}
.rtl .slider-text,
.rtl .slider-text-bottom {
  right: 50%;
  left: auto;
  transform: translate(50%, -50%);
  text-align: center;
}
</style>
