<template>
  <div class="card-container hover:bg-gray-100" :dir="locale === 'ar' || locale === 'kr' ? 'rtl' : 'ltr'">
    <div class="image-container">
      <img v-if="hotelsInfo.image_url" :src="hotelsInfo.image_url" alt="" />
      <img v-if="!hotelsInfo.image_url" src="@/assets/imgs/img.jpg" alt="" />
    </div>
    <div class="card-details">
      <div class="card-text">
        <div class="header-text-container">
          <div style="display: flex">
            <h1 style="font-weight: 600; font-size: 16px">{{ hotelsInfo.name }}</h1>
          </div>
        </div>
        <div style="display: flex; gap: 10px">
          <span style="color: #888" class="ml-1">
            <v-icon size="18" class="text-#888" icon="mdi-bed-queen-outline"></v-icon>
            {{ hotelsInfo.bathroom_number }} {{ $t("message.bedroom") }}
          </span>
          <span style="color: #888">
            <v-icon
              size="18"
              class="text-#888 ml-1"
              icon="mdi-account-multiple-outline"
            ></v-icon>
            {{ $t("message.accommodates") }} {{ hotelsInfo.adult_number }}
            {{ $t("message.persons") }}
          </span>
        </div>
        <div
          style="
            display: flex;
            flex-direction: column;
            color: #4347b6;
            font-weight: 700;
            font-size: 13px;
          "
        >
          <span dir="ltr">{{ hotelsInfo.total_price_with_currency }} {{ $store.state.users.currency_selected }}</span>
          <span>{{ $t("message.includingTaxes") }}</span>
        </div>
      </div>
      <div>
        <v-select
          v-model="selectedRoomCount"
          :items="getRoomCounts(hotelsInfo.total_price_with_currency, hotelsInfo.room_number)"
          item-title="text"
          item-value="value"
          label=""
          density="compact"
          variant="outlined"
        ></v-select>
      </div>
    </div>
  </div>
</template>
<script setup>
import { onMounted } from "vue";
import { ref, defineProps } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";

const { locale } = useI18n();
const props = defineProps(["hotelsData"]);
const store = useStore()
const hotelsInfo = ref({});
const showHotelOpptions = ref(false);
const selectedRoomCount = ref(1); // Add this line

const showHotel = async () => {
  try {
    hotelsInfo.value = props.hotelsData;
    showHotelOpptions.value = true;
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

// Method to generate room counts with price
const getRoomCounts = (price, roomNumber) => {
  return Array.from({ length: roomNumber }, (_, i) => ({
    text: `${i + 1} ${i == 0 ? "room" : "rooms"} (${(price * (i + 1)).toLocaleString()} ${store.state.users.currency_selected})`,
    value: i + 1,
  }));
};

onMounted(showHotel);
</script>

<style scoped>
.card-container {
  font-family: "Cairo", sans-serif;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 145px;
  width: 100%;
  border-radius: 10px;
  margin: auto;
}
.card-container:hover {
  cursor: pointer;
  transform: scale(1.01);
  transition: all 0.4s ease-in-out;
}
.card-text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 12px;
  justify-content: space-between;
  padding: 15px;
  gap: 10px;
}
.card-details {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
}
.image-container {
  height: 90%;
  margin: 5px;
}
.image-container img {
  width: 140px;
  height: 129px;
  border-radius: 10px;
  object-fit: cover;
  display: block;
}
.header-text-container {
  display: flex;
  justify-content: space-between;
}

@media (max-width: 1500px) {
  .card-text {
    font-size: 10px;
    gap: 10px;
  }
}
@media (max-width: 1000px) {
  .card-container {
    font-family: "Cairo", sans-serif;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 205px;
    width: 100%;
  }
  .card-details {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
}
</style>
